import { AdAccount, AdAccountResponse } from "~/types/AdAccount";

export const useAdAccounts = () => {
  const getMappedAdAccounts = (
    adAccounts: Array<AdAccountResponse>,
  ): Array<AdAccount> => {
    const clientStore = useClientStore();
    const { getProviderLogo, providerToDisplayName } = useConnection();

    const mappedAdAccounts = adAccounts.map<AdAccount>((adAccount) => {
      const client = clientStore.getClientById(adAccount.clientId);

      return {
        ...adAccount,
        displayName: adAccount.name,
        logo: getProviderLogo(adAccount.provider),
        providerDisplayName: providerToDisplayName(adAccount.provider),
        client,
        clientDisplayName: client != null ? client.displayName : "---",
        deleted:
          typeof adAccount.deleted === "boolean" ? adAccount.deleted : false,
      };
    });

    mappedAdAccounts.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );

    return mappedAdAccounts;
  };

  return { getMappedAdAccounts };
};
