import { createAvatar } from "~/shared/utils";
import { Client, ClientResponse } from "~/types/Client";

export const useClients = () => {
  const getMappedClients = (
    clients: Array<ClientResponse>,
    sortFn?: (a: Client, b: Client) => number,
  ): Array<Client> => {
    const mappedClients = clients.map((client) => {
      const displayImage =
        typeof client.logo === "string" && client.logo.length > 0
          ? client.logo
          : createAvatar(client.name, "initials");

      return {
        ...client,
        displayName: client.name,
        displayWebsite:
          typeof client.website === "string" && client.website.length > 0
            ? client.website
            : "---",
        displayImage,
      };
    });

    if (sortFn != null) {
      mappedClients.sort(sortFn);
    }

    return mappedClients;
  };

  return {
    getMappedClients,
  };
};
